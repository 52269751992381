<template>
  <div class="has-text-weight-bold has-text-centered main-message">
    Social Login in Progress...
  </div>
</template>
<script>
import desktopUiFixes from '@utils/mixins/desktop-no-common-parts-ui-fixes.js'
import ssoMixins from '@utils/mixins/sso-mixins.js'
import { embeddedInIos } from '@utils/common-methods/common'

export default {
  mixins: [desktopUiFixes, ssoMixins],
  mounted() {
    if (embeddedInIos()) return window.webkit.messageHandlers.closeWebview.postMessage(true)
    this.refreshInvestorData()
  },
  methods: {
    async refreshInvestorData() {
      await this.refreshStorageForSSO()
      if (this.isCountrySet()) {
        this.$router.push('/dashboard')
      } else {
        const addyHandle = this.$route.query.addyHandle
        const url = addyHandle ? `/signup/selectProvince?addyHandle=${addyHandle}` : '/signup/selectProvince'
        this.$router.push(url)
      }
    },
    isCountrySet() {
      const investor = localStorage.getItem('investor')
      const {
        country,
        administrativeAreaLevel1
      } = JSON.parse(investor)
      return country && administrativeAreaLevel1
    }
  },
}
</script>
<style lang="scss" scoped>
.main-message {
  font-size: 20px;
  height: 100vh;
  line-height: 100vh;
}
</style>
