import { getInvestorData } from '@api/signup'
import { getMembershipDetails } from '@api/common'
import { getSharedCookieDomain } from '../common-methods/getSharedCookieDomain'

const ssoMixins = {
  methods: {
    async refreshStorageForSSO(investorData) {
      if (this.$cookies.isKey('needs_sso_refresh')) {
        this.$cookies.remove('needs_sso_refresh', '/', getSharedCookieDomain())

        if (!investorData) {
          investorData = await getInvestorData()
        }

        localStorage.setItem('investor', JSON.stringify(investorData))
        if (this.mixpanel) {
          this.mixpanel.identify(investorData.email)
          this.$store.commit('mixpanel/setIdentity', true)
        }

        getMembershipDetails().then((response) => {
          if (response.success) localStorage.setItem('membershipDetails', JSON.stringify(response.data))
        })
      }
    },
  },
}
export default ssoMixins
